import { IngredientType } from '../types/IngredientType';
import { BundleLineItem, BundleRecipe } from '../bonsaiClient';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type BaseEventData = {
  eventSourcePage: EventSourcePage;
  eventSourceComponent?: EventSourceComponent;
  utmMedium?: string;
  campaignId?: string;
};

export enum EventSourcePage {
  RecipePage = 'recipe-page',
  BrandPage = 'brand-page',
  CartPage = 'cart-page',
  DigiPage = 'digi-page',
}

export enum EventSourceComponent {
  RecipeCard = 'recipe-card',
  ProductCard = 'product-card',
  RecipeDialog = 'recipe-dialog',
  ProductDialog = 'product-dialog',
}

enum GtmEvents {
  VIEW_BRAND_PAGE = 'view_brand_page',
  VIEW_RECIPE_PAGE = 'view_recipe_page',
  VIEW_PRODUCT_PAGE = 'view_product_page',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  REMOVE_ITEM_FROM_CART = 'remove_item_from_cart',
  ADD_TO_RETAILER = 'add_to_retailer',
  VIEW_CART_PAGE = 'view_cart_page',
}

type TrackViewBrandPage = {
  companyName: string;
  brandName: any;
  brandType: string; // brand, digi
  currentUserId: string;
  campaignId: string;
  brandId: string;
  store?: any;
} & BaseEventData;

function getStoreEventData(store?: any) {
  if (!store) return {};
  return {
    retailerName: store.retailerName,
    storeId: store.storeId,
    storeName: store.storeName,
    instacartRetailerId: store.instacartRetailerId,
    address: store.address,
    city: store.city,
    state: store.state,
    zipCode: store.zipCode,
    location: store.location,
  };
}

export const trackViewBrandPage = (data: TrackViewBrandPage) => {
  const { store, eventSourcePage, eventSourceComponent, ...rest } = data;
  window.dataLayer.push({
    _clear: true,
    event: GtmEvents.VIEW_BRAND_PAGE,
    ...rest,
    store: getStoreEventData(store),
    eventSourcePage,
    eventSourceComponent,
  });
};

type TrackViewRecipePage = {
  companyName: string;
  brandName: any;
  currentUserId: string;
  campaignId: string;
  brandId: string;
  store?: any;
  recipe: BundleRecipe;
} & BaseEventData;

function getRecipeEventData(recipe: Partial<BundleRecipe>) {
  return {
    id: recipe.id,
    source: '', // TODO:// basketful?
    url: '', // TODO:// source url?
    name: recipe.title,
  };
}

export const trackViewRecipePage = (data: TrackViewRecipePage) => {
  const { store, recipe, eventSourcePage, eventSourceComponent, ...rest } =
    data;
  window.dataLayer.push({
    _clear: true,
    event: GtmEvents.VIEW_RECIPE_PAGE,
    ...rest,
    store: getStoreEventData(store),
    recipe: getRecipeEventData(recipe),
    eventSourcePage,
    eventSourceComponent,
  });
};

function getProductEventData(product: Partial<BundleLineItem>) {
  return {
    id: product.id,
    name: product.name,
    brand: undefined, // TODO:// we have no brand at this point, brand Id?
  };
}

type TrackCart = {
  companyName: string;
  brandName?: any;
  currentUserId: string;
  campaignId: string;
  store?: any;
  lineItem?: BundleLineItem;
  recipe?: BundleRecipe;
} & BaseEventData;

export const trackAddToCart = (data: TrackCart) => {
  const {
    store,
    lineItem,
    recipe,
    eventSourcePage,
    eventSourceComponent,
    ...rest
  } = data;

  let itemType = '';
  let itemId = '';
  let itemName = '';
  if (lineItem) {
    itemType = 'product';
    itemId = lineItem.id;
    itemName = lineItem.name;
  } else if (recipe) {
    itemType = 'recipe';
    itemId = recipe.id;
    itemName = recipe.title;
  }

  window.dataLayer.push({
    _clear: true,
    event: GtmEvents.ADD_TO_CART,
    ...rest,
    itemType,
    itemId,
    itemName,
    store: getStoreEventData(store),
    product: lineItem && getProductEventData(lineItem),
    recipe: recipe && getRecipeEventData(recipe),
    eventSourcePage,
    eventSourceComponent,
  });
};

export const trackRemoveFromCart = (data: TrackCart) => {
  const {
    store,
    lineItem,
    recipe,
    eventSourcePage,
    eventSourceComponent,
    ...rest
  } = data;

  let itemType = '';
  let itemId = '';
  let itemName = '';
  if (lineItem) {
    itemType = 'product';
    itemId = lineItem.id;
    itemName = lineItem.name;
  } else if (recipe) {
    itemType = 'recipe';
    itemId = recipe.id;
    itemName = recipe.title;
  }

  window.dataLayer.push({
    _clear: true,
    event: GtmEvents.REMOVE_FROM_CART,
    ...rest,
    itemType,
    itemId,
    itemName,
    store: getStoreEventData(store),
    product: lineItem && getProductEventData(lineItem),
    recipe: recipe && getRecipeEventData(recipe),
    eventSourcePage,
    eventSourceComponent,
  });
};

type RemoveItemFromCart = Partial<TrackCart> & {
  ingredient?: IngredientType;
  itemType?: 'product' | 'recipe' | 'ingredient';
} & BaseEventData;

export const trackRemoveItemFromCart = (data: RemoveItemFromCart) => {
  const {
    store,
    lineItem,
    recipe,
    itemType: givenItemType,
    ingredient,
    eventSourcePage,
    eventSourceComponent,
    ...rest
  } = data;

  let itemType = ''; // product | recipe | ingredient
  let itemId = '';
  let itemName = '';
  if (lineItem) {
    itemType = 'product';
    itemId = lineItem.id;
    itemName = lineItem.name;
  } else if (recipe) {
    itemType = 'recipe';
    itemId = recipe.id;
    itemName = recipe.title;
  } else if (ingredient) {
    itemType = 'ingredient';
    itemId = ingredient?.id;
    itemName = ingredient.ingredientName;
  }

  window.dataLayer.push({
    _clear: true,
    event: GtmEvents.REMOVE_ITEM_FROM_CART,
    ...rest,
    itemType: givenItemType || itemType,
    itemId,
    itemName,
    store: getStoreEventData(store as any),
    product: lineItem && getProductEventData(lineItem),
    recipe: recipe && getRecipeEventData(recipe),
    ingredient: undefined, // no more ingredients in myxx
    eventSourcePage,
    eventSourceComponent,
  });
};
