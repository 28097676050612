import { useRouter } from 'next/router';
import BrandPageLayout from '../layouts/BrandPageLayout/BrandPageLayout';
import Health from './health';

const DefaultPage = () => {
  const router = useRouter();
  const utmData = router.query;
  if (router.route === '/health') {
    return <Health />;
  } else {
    return <BrandPageLayout utmData={utmData} />;
  }
};

export default DefaultPage;
