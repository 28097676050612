import { makeStyles } from '@mui/styles';

const RecipeDialogStyles = makeStyles(theme => ({
  recipeCard: {
    '@media (min-width: 1025px)': {
      width: '80%',
      marginLeft: '10%',
      height: '82vh',
      marginTop: '9vh',
    },
    '@media (min-width: 1200px)': {
      width: '70%',
      marginLeft: '15%',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    height: '32px',
    width: '32px',
    right: 8,
    top: 8,
    boxShadow: '-2px 3px 3px #0000004D',
    '&:hover': {
      backgroundColor: '#FFFFFF80',
    },
    '@media (min-width: 1025px)': {
      boxShadow: 'none',
    },
  },
  closeIcon: {
    height: '24px',
    width: '24px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default RecipeDialogStyles;
