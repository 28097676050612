import { Dispatch } from '@reduxjs/toolkit';

import {
  AddBundleOptions,
  getBasket,
  addBundle,
  RemoveBundleOptions,
  removeBundle,
  AddBundleItemOptions,
  addBundleItem,
  RemoveBundleItemOptions,
  removeBundleItem,
  BasketBundleRemoved,
  BasketItemRemoved,
} from '../../util/basketfuljsClient';
import { recipeActions } from '../states/recipeSlice';
import {
  ContentfulBrand,
  getBrandDetails,
  getBundleLineItems,
  getBundleRecipes,
} from '../../util/bonsaiClient';
import { sendSentryError } from '../../util/sentry';

const {
  setError,
  setBundleRecipes,
  setBundleLineItems,
  setSelectedRecipeId,
  setSiteBasket,
  addListBundle,
  removeListBundle,
  setBasketfulJsIsReady,
  setRecipeIsBusy,
  setSiteBasketCount,
  addListBundleItem,
  removeListBundleItem,
  setBrandDetails,
  resetSiteBasket,
} = recipeActions;

export const sendAndSetErrorException =
  (err: Error) => async (dispatch: any & Dispatch<any>) => {
    sendSentryError(err);
    const { message } = err;
    dispatch(setError(message));
  };

export const fetchBundleRecipeAction =
  (bundleIds: string[]) =>
  async (dispatch: any): Promise<any> => {
    try {
      const bundleRecipes = await getBundleRecipes(bundleIds);
      dispatch(setBundleRecipes(bundleRecipes));
    } catch (err) {
      sendAndSetErrorException(err as Error);
    }
  };

export const fetchBundleBundleLineItemsAction =
  (bundleIds: string[]) =>
  async (dispatch: any): Promise<any> => {
    try {
      const bundleLineItems = await getBundleLineItems(bundleIds);
      dispatch(setBundleLineItems(bundleLineItems));
    } catch (err) {
      sendAndSetErrorException(err as Error);
    }
  };

export const setSelectedRecipeIdAction =
  (recipeId?: string) => (dispatch: Dispatch) => {
    dispatch(setSelectedRecipeId(recipeId));
  };

export const fetchSiteBasketAction =
  (forceReload?: boolean): any =>
  async (dispatch: any) => {
    dispatch(setRecipeIsBusy(true));
    try {
      const res = await getBasket(forceReload);
      dispatch(setSiteBasket(res));
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const addSiteBundleAction =
  (options: AddBundleOptions): any =>
  async (dispatch: any) => {
    dispatch(setRecipeIsBusy(true));
    try {
      const res = await addBundle(options);
      const listBundle = {
        ...res.basketBundle,
      };
      dispatch(addListBundle(listBundle));
      dispatch(setSiteBasketCount(res.count));
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const removeSiteBundleAction =
  (options: RemoveBundleOptions): any =>
  async (dispatch: any) => {
    dispatch(setRecipeIsBusy(true));
    try {
      const res = await removeBundle(options);
      const listBundle = {
        ...res,
      };
      dispatch(removeListBundle(listBundle));
      dispatch(setSiteBasketCount(listBundle.count));
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const siteBundleRemovedAction =
  (bundleItem: BasketBundleRemoved): any =>
  async (dispatch: any) => {
    dispatch(removeListBundle(bundleItem.item));
    dispatch(setSiteBasketCount(bundleItem.itemCount));
    dispatch(setRecipeIsBusy(false));
  };

export const siteBundleItemRemovedAction =
  (itemItem: BasketItemRemoved): any =>
  async (dispatch: any) => {
    dispatch(removeListBundleItem(itemItem.item));
    dispatch(setSiteBasketCount(itemItem.itemCount));
    dispatch(setRecipeIsBusy(false));
  };

export const setBasketfulJsIsReadyAction =
  (isReady: boolean): any =>
  (dispatch: any): any => {
    dispatch(setBasketfulJsIsReady(isReady));
  };

export const setRecipeIsBusyAction =
  (recipeIsBusy: boolean) => (dispatch: Dispatch) => {
    dispatch(setRecipeIsBusy(recipeIsBusy));
  };

export const setSiteBasketCountAction =
  (count: number) => (dispatch: Dispatch) => {
    dispatch(setSiteBasketCount(count));
  };

export const addSiteBundleLineItemAction =
  (options: AddBundleItemOptions): any =>
  async (dispatch: any) => {
    dispatch(setRecipeIsBusy(true));
    try {
      const listItem = await addBundleItem(options);
      dispatch(addListBundleItem(listItem.basketItem));
      dispatch(setSiteBasketCount(listItem.count));
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const removeSiteBundleLineItemAction =
  (options: RemoveBundleItemOptions): any =>
  async (dispatch: any) => {
    dispatch(setRecipeIsBusy(true));
    try {
      const listItem = await removeBundleItem(options);
      dispatch(removeListBundleItem(listItem.item));
      dispatch(setSiteBasketCount(listItem.count));
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const getBrandDetailsAndBasketfulContentAction =
  (hostname: string) =>
  async (dispatch: any): Promise<any> => {
    dispatch(setRecipeIsBusy(true));
    try {
      const res: ContentfulBrand[] = await getBrandDetails(hostname);
      const brandDetails = res[0]?.fields ?? {}; // default to first since we are querying by hostname));
      await dispatch(setBrandDetails(brandDetails));
      if (
        brandDetails?.basketfulRecipeIds &&
        brandDetails.basketfulRecipeIds?.length > 0
      ) {
        await dispatch(
          fetchBundleRecipeAction(brandDetails.basketfulRecipeIds),
        );
      }

      if (
        brandDetails?.basketfulTemplateIds &&
        brandDetails.basketfulTemplateIds?.length > 0
      ) {
        await dispatch(
          fetchBundleBundleLineItemsAction(brandDetails.basketfulTemplateIds),
        );
      }
      dispatch(setRecipeIsBusy(false));
    } catch (err) {
      sendAndSetErrorException(err as Error);
      dispatch(setRecipeIsBusy(false));
    }
  };

export const resetSiteBasketAction = () => (dispatch: Dispatch) => {
  dispatch(resetSiteBasket());
};
