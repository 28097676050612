import React, { FC, useState, useRef, useEffect, memo } from 'react';
import type { LottiePlayer } from 'lottie-web';
import chef from '../../lotties/chef.json';

interface ChefLoaderProps {
  size?: string;
  loadingText?: string;
}

const ChefLoader: FC<ChefLoaderProps> = ({ size, loadingText }) => {
  const container = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then(Lottie => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && container.current) {
      const animation = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: chef,
      });

      return () => animation.destroy();
    }
    return undefined;
  }, [lottie]);

  return (
    <div
      style={{
        display: 'flex',
        height: '80vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      data-testid="chef-loader"
    >
      <div
        style={{
          width: size,
          height: size,
        }}
        ref={container}
      />
      {loadingText}
    </div>
  );
};

export default memo(ChefLoader);
