import React, { FC, memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Image from 'next/legacy/image';
import { placeholderUrl } from '../../../util/functions/ui.functions';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    height: '52px',
    zIndex: 500,
    '@media (min-width: 768px)': {
      padding: '0px 8px',
      height: '72px',
    },
    '@media (min-width: 1025px)': {
      padding: '0px',
    },
  },
  logoContainerNoMT: {
    position: 'relative',
    minWidth: '100px',
    maxWidth: '100px',
    minHeight: '40px',
    maxHeight: '40px',
    '@media (min-width: 768px)': {
      minWidth: '170px',
      maxWidth: '170px',
      minHeight: '55px',
      maxHeight: '55px',
    },
  },
  logoCenter: {
    position: 'absolute',
    top: 8,
    left: '25%',
    width: '50%',
    textAlign: 'center',
    '@media (min-width: 768px)': {
      top: 8,
    },
    '@media (min-width: 1025px)': {
      textAlign: 'left',
      left: 0,
      width: '20%',
      maxWidth: '100px',
    },
  },
  imgLogo: {
    position: 'absolute',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  storeCenter: {
    position: 'absolute',
    width: '70%',
    textAlign: 'center',
    top: '48px',
    left: '15%',
    height: '24px',
    fontSize: '12px',
    '@media (min-width: 768px)': {
      textAlign: 'right',
      top: 10,
      width: '30%',
      left: '70%',
    },
  },
  storeButton: {
    '@media (min-width: 768px)': {
      fontSize: '16px',
      padding: '8px 8px 8px 24px',
      borderRadius: '4px',
      '&:hover': {
        boxShadow: '2px 2px 2px 1px #00000030',
      },
    },
  },
  roomIcon: {
    position: 'absolute',
    left: -14,
    top: 0,
    height: '14px',
    width: '14px',
    '@media (min-width: 768px)': {
      top: 8,
      left: 0,
      height: '20px',
      width: '20px',
    },
  },
  cityZip: {
    fontWeight: 300,
    marginTop: '2px',
    fontSize: '12px',
    '@media (min-width: 768px)': {
      fontSize: '14px',
    },
  },
  storeInfo: {
    display: 'block',
    '@media (min-width: 768px)': {
      textAlign: 'right',
    },
  },
});

interface BPHeaderProps {
  url: string;
  logoUrl: string;
  logoImageAlt?: string;
}

const BPHeader: FC<BPHeaderProps> = ({ url, logoUrl, logoImageAlt }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <AppBar position="static" color="inherit" style={{ boxShadow: 'none' }}>
        <Toolbar variant="dense">
          <div className={classes.logoCenter}>
            <ButtonBase
              onClick={() => {
                window.open(url);
              }}
              className={classes.logoContainerNoMT}
            >
              {logoUrl && (
                <Image
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
                  src={logoUrl}
                  alt={logoImageAlt ?? 'brand logo'}
                  className={classes.imgLogo}
                  layout="fill"
                />
              )}
            </ButtonBase>
          </div>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default memo(BPHeader);
