import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/legacy/image';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { selectRecipeIsBusy } from '../../../redux/selectors/recipeSelectors';
import { MISSING_IMAGE_URL } from '../../../util/constants';
import { placeholderUrl } from '../../../util/functions/ui.functions';
import { BundleRecipe } from 'src/util/bonsaiClient';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    textAlign: 'start',
    boxShadow: 'none',
    marginBottom: '16px',
    height: '228px',
  },
  content: {
    padding: '8px 4px',
    fontSize: '0.875rem',
    minHeight: '66px',
    fontWeight: 500,
    '@media (min-width: 900px)': {
      fontSize: '1rem',
    },
  },
  add: {
    position: 'absolute',
    bottom: 0,
    borderRadius: '4px',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
  recipeImage: {
    cursor: 'pointer',
    '&:focus': {
      outlineOffset: 8,
      border: '3px solid red',
    },
  },
});

interface BrandRecipeCardProps {
  recipe: BundleRecipe;
  viewRecipe: (recipe: BundleRecipe) => void;
  customTheme: any;
  isInBasket: boolean;
  addRecipe: () => void;
  removeRecipe: () => void;
}

const BrandRecipeCard: FC<BrandRecipeCardProps> = ({
  recipe,
  viewRecipe,
  customTheme,
  isInBasket,
  addRecipe,
  removeRecipe,
}) => {
  const classes = useStyles();
  const recipeIsBusy = useSelector(selectRecipeIsBusy);
  const handleViewRecipe = (event: any) => {
    if (event?.key === 'Enter' || event?.key === ' ') {
      viewRecipe(recipe);
      return;
    }

    if (event?.type === 'click') {
      viewRecipe(recipe);
      return;
    }
  };

  const nameShortened =
    recipe?.title?.length > 56
      ? `${recipe?.title?.slice(0, 56)}...`
      : recipe.title;

  return (
    <Card className={classes.root}>
      <Box
        className={classes.recipeImage}
        role="button"
        tabIndex={0}
        onClick={handleViewRecipe}
        aria-label={`View ${recipe?.title} recipe`}
        onKeyDown={handleViewRecipe}
      >
        <div
          style={{
            borderRadius: '12px',
            height: '8.25rem',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Image
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
            src={recipe.imageUrl || MISSING_IMAGE_URL}
            alt={recipe.heroAltText}
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className={classes.content}>{nameShortened}</div>
      </Box>
      {isInBasket ? (
        <Button
          onClick={removeRecipe}
          fullWidth
          style={{
            backgroundColor: customTheme.palette.primary.main,
          }}
          className={classes.add}
          size="small"
          aria-label={`Remove ${recipe.title} recipe to cart`}
          disabled={recipeIsBusy}
        >
          Remove from Cart
        </Button>
      ) : (
        <Button
          onClick={addRecipe}
          fullWidth
          style={{
            backgroundColor: customTheme.palette.primary.main,
          }}
          className={classes.add}
          size="small"
          aria-label={`Add ${recipe.title} recipe to cart`}
          disabled={recipeIsBusy}
        >
          Add to Cart
        </Button>
      )}
    </Card>
  );
};

export default memo(BrandRecipeCard);
