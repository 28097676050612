import axios from 'axios';

export type BundleRecipe = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  ingredients: Array<{
    id: string;
    ingredient: string;
  }>;
  directions: Array<{
    text: string;
  }>;
  yield?: string;
  cookTime?: string;
  prepTime?: string;
  calories?: number;
  source?: string;
  tags?: string[];
  popularityRank?: number;
  url?: string; // TODO: needed?
  rating: string;
  heroAltText: string;
};

export type ContentfulBrand = {
  fields: ContentfulBrandFields;
  metadata: {
    tags: string[];
  };
  sys: any;
};

export type ContentfulBrandFields = {
  basketfulRecipeIds?: string[];
  basketfulTemplateIds?: string[];
  basketfulPrimaryColor?: string;
  basketfulSecondaryColor?: string;
  brand: string;
  brandVideo: string;
  campaignId: string;
  companyName: string;
  heroImage: any;
  logo: any;
  section1Header: string;
  section2Header: string;
  section3Header: string;
  section4Header: string;
  website: string;
  primaryMain?: string;
  primaryDark?: string;
  primaryLight?: string;
  secondaryDark?: string;
  secondaryLight?: string;
  description?: string;
  description2?: string;
  description3?: string;
  heroText?: string;
  heroText2?: string;
  heroText3?: string;
  heroTextSpacing?: string;
  heroTextFontsizeMobile?: string;
  heroTextColor?: string;
  heroTextShadowColor?: string;
  heroTextLineHeight?: string;
  heroTextFont?: string;
  heroTextFontStyle?: string;
  heroTextFont2?: string;
  heroTextFontStyle2?: string;
  heroTextFont3?: string;
  heroTextFontStyle3?: string;
  heroSubText?: string;
  heroSubFont?: string;
  heroSubFontsize?: string;
  heroAlign:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  heroSubFontStyle?: string;
  sectionImage?: any;
  heroTextFontsize?: string;
  heroImageAltText?: string;
  pageTitle?: string;
  logoImageAlt?: string;
  sectionImageAltText?: string;
};

export const getBundleRecipes = async (
  bundleIds: string[],
): Promise<BundleRecipe[]> => {
  const host = `${process.env.NEXT_PUBLIC_BONSAI_SERVICE_URL}/Content/bundleRecipes`;
  const headers = {
    Accept: 'application/json',
  };
  const contentType = { 'content-type': 'application/json' };
  const options = { headers };
  options.headers = Object.assign(options.headers, contentType);
  const body = { bundleIds };
  const response = await axios.post(`${host}`, body, options);
  return response.data;
};

export type BundleLineItem = {
  id: string;
  name: string;
  imageUrl: string;
  upc: string;
};

export type BundleLineItems = {
  id: string;
  name: string;
  lineItems: BundleLineItem[];
};

export const getBundleLineItems = async (
  bundleIds: string[],
): Promise<BundleLineItems[]> => {
  const host = `${process.env.NEXT_PUBLIC_BONSAI_SERVICE_URL}/Content/bundleLineItems`;
  const headers = {
    Accept: 'application/json',
  };
  const contentType = { 'content-type': 'application/json' };
  const options = {
    headers,
  };
  options.headers = Object.assign(options.headers, contentType);
  const body = { bundleIds };
  const response = await axios.post(`${host}`, body, options);
  return response.data;
};

export const getBrandDetails = async (
  hostname: string,
): Promise<ContentfulBrand[]> => {
  const host = `${process.env.NEXT_PUBLIC_BONSAI_SERVICE_URL}/Myxx/brandPage`;
  const headers = {
    Accept: 'application/json',
  };
  const contentType = { 'content-type': 'application/json' };
  const options = {
    headers,
  };
  options.headers = Object.assign(options.headers, contentType);
  const body = { hostname };
  const response = await axios.post(`${host}`, body, options);
  return response.data;
};
