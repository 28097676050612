declare global {
  interface Window {
    basketful: any;
  }
}

if (typeof window !== 'undefined') {
  // browser code / not server
  window.basketful = window.basketful || undefined;
}

interface ListItem {
  id: string;
  upc: string;
  quantity: string;
}

interface ListRecipe {
  id: string;
  url: string;
  title: string;
}

interface ListBundle {
  id: string;
  canonicalUrl: string;
  title: string;
}

export interface SiteListBasket {
  id: string;
  listItems: ListItem[];
  listRecipes: ListRecipe[];
  listBundles: ListBundle[];
  count: number;
}

export interface AddBundleOptions {
  id: string;
  canonicalUrl: string;
  buttonType?: string;
}

export interface AddBundleItemOptions {
  upc: string;
  quantity?: number;
  buttonType?: string;
}

export interface RemoveBundleItemOptions {
  id?: string;
  upc?: string;
  buttonType?: string;
}

export interface RemoveBundleOptions {
  id: string;
  buttonType?: string;
}

export interface BasketItem {
  id: string;
  upc: string;
  quantity: number;
}

export interface BasketBundleLineItem {
  basketItem: BasketItem;
  count: number;
  modifiedItemCount: number;
}

export interface BasketBundleLineItemRemoved {
  id: string;
  count: number;
  deletedItemCount: number;
  item: {
    id: string;
    upc: string;
  };
}

export interface BasketBundle {
  basketBundle: BasketItem;
  count: number;
  modifiedItemCount: number;
}

export interface BasketBundleRemoved {
  item: {
    id: string;
  };
  itemCount: number;
  deletedItemCount: number;
}

export interface BasketItemRemoved {
  item: {
    id: string;
    upc: string;
  };
  itemCount: number;
  deletedItemCount: number;
}

export interface SiteBasketOpenOptions {
  viewMode?: 'Flat' | 'Grouped';
  autoCart?: boolean;
  selectedRetailerId?: string;
  buttonType?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

export const openSiteBasket = (options?: SiteBasketOpenOptions): void =>
  window.basketful.siteBasket.open(options);

export async function getBasket(
  forceReload?: boolean,
): Promise<SiteListBasket> {
  const res = await window.basketful.siteBasket.getBasket(forceReload);
  return res;
}

export async function addBundle(
  options: AddBundleOptions,
): Promise<BasketBundle> {
  const res = await window.basketful.siteBasket.addBundle(options);
  return res;
}

export async function removeBundle(
  options: RemoveBundleOptions,
): Promise<BasketBundle> {
  const res = await window.basketful.siteBasket.removeBundle(options);
  return res;
}

export async function addBundleItem(
  options: AddBundleItemOptions,
): Promise<BasketBundleLineItem> {
  const res = await window.basketful.siteBasket.addItem(options);
  return res;
}

export async function removeBundleItem(
  options: RemoveBundleItemOptions,
): Promise<BasketBundleLineItemRemoved> {
  const res = await window.basketful.siteBasket.removeItem(options);
  return res;
}
