import { makeStyles } from '@mui/styles';

const BrandPageLayoutStyles = (customTheme: { [key: string]: any }) =>
  makeStyles({
    cartButton: {
      boxShadow: '0px 0px 6px #00000050',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 400,
      height: '76px',
      width: '76px',
      borderRadius: '50%',
      position: 'relative',
      backgroundColor: customTheme.palette.primary.dark,
      '&:hover': {
        backgroundColor: customTheme.palette.primary.light,
      },
      '@media (min-width: 768px)': {
        width: '96px',
        height: '96px',
      },
    },
    wholeContainer: {
      '@media (min-width: 1025px)': {
        width: '80%',
        marginLeft: '10%',
      },
      '@media (min-width: 1200px)': {
        width: '74%', // this ensures that the "live" area never gets clipped
        marginLeft: '15%',
      },
      '@media (min-width: 1920px)': {
        marginLeft: 'calc(50% - 672px)',
      },
      maxWidth: '1344px',
    },
    gridContainer: {
      margin: '8px',
      '@media (min-width: 1025px)': {
        margin: '8px 0px',
      },
    },
    heroContainer: {
      position: 'relative',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '184px',
      maxHeight: '184px',
      '@media (min-width: 452px)': {
        minHeight: '220px',
        maxHeight: '220px',
      },
      '@media (min-width: 740px)': {
        minHeight: '360px',
        maxHeight: '360px',
      },
      '@media (min-width: 1200px)': {
        minHeight: '420px',
        maxHeight: '420px',
      },
    },
    heroImage: {
      position: 'relative',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    heroText: {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      textAlign: 'center',
      fontWeight: 600,
    },
    brandImageContainer: {
      position: 'relative',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '152px',
      maxHeight: '152px',
      '@media (min-width: 768px)': {
        minHeight: '95%',
        maxHeight: '95%',
      },
    },
    brandImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    footerButtons: {
      fontSize: '12px',
      marginTop: '8px',
    },
    footerButton: {
      fontSize: '12px',
      textDecoration: 'underline',
    },
    cartButtonContainer: {
      position: 'fixed',
      bottom: 8,
      right: 8,
      width: '76px',
      display: 'flex',
      justifyContent: 'center',
      '@media (min-width: 768px)': {
        right: '6%',
        bottom: 24,
        width: '96px',
      },
      '@media (min-width: 1025px)': {
        right: '12%',
      },
      '@media (min-width: 1920px)': {
        right: 'calc(40vw - 440px)',
      },
      '@media (min-width: 2700px)': {
        right: 'calc(40vw - 350px)',
      },
    },
    cartIcon: {
      height: '34px',
      width: '34px',
      color: 'white',
      '@media (min-width: 900px)': {
        height: '40px',
        width: '40px',
      },
    },
    cartIconCC: {
      height: '34px',
      width: '34px',
      color: 'white',
      marginBottom: '-10px',
      '@media (min-width: 768px)': {
        height: '40px',
        width: '40px',
      },
    },
    cartCount: {
      color: 'white',
      fontSize: '10px',
      '@media (min-width: 768px)': {
        fontSize: '12px',
      },
    },
    sectionHeader: {
      fontSize: '18px',
      fontWeight: 600,
      '@media (min-width: 900px)': {
        fontSize: '32px',
      },
    },
    logo: {
      objectFit: 'contain',
    },
    recipeCard: {
      '@media (min-width: 768px)': {
        maxWidth: '25%',
      },
      '@media (min-width: 1600px)': {
        maxWidth: '20%',
      },
    },
    brandImageGrid: {
      '@media (min-width: 768px)': {
        maxWidth: '50%',
      },
      '@media (min-width: 1200px)': {
        minHeight: '350px',
      },
    },
    brandText: {
      '@media (min-width: 768px)': {
        maxWidth: '50%',
      },
    },
    iFrameContainer: {
      width: '100%',
      paddingTop: '56.25%',
      position: 'relative',
    },
    iFrame: {
      top: 0,
      width: '100%',
      height: '100%',
    },
  });

export default BrandPageLayoutStyles;
