import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Image from 'next/legacy/image';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import ButtonBase from '@mui/material/ButtonBase';

import Iframe from 'react-iframe';
import { useMediaQuery } from 'react-responsive';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  SMART_COMMERCE_ORANGE,
  SMART_COMMERCE_PRIVACY_POLICY_URL,
  SMART_COMMERCE_PURPLE,
} from '../../util/constants';
import Mixpanel from '../../util/mixpanel';
import {
  BasketBundleRemoved,
  BasketItemRemoved,
  openSiteBasket,
} from '../../util/basketfuljsClient';
import PageHead from '../../components/Head/Head';
import RecipeDialog from '../recipeDialog/RecipeDialog';
import ChefLoader from '../../components/Loading/ChefLoader';
import {
  addSiteBundleAction,
  addSiteBundleLineItemAction,
  fetchSiteBasketAction,
  getBrandDetailsAndBasketfulContentAction,
  removeSiteBundleAction,
  removeSiteBundleLineItemAction,
  resetSiteBasketAction,
  setBasketfulJsIsReadyAction,
  setSelectedRecipeIdAction,
  siteBundleItemRemovedAction,
  siteBundleRemovedAction,
} from '../../redux/actions/recipeActions';
import {
  EventSourceComponent,
  EventSourcePage,
  trackAddToCart,
  trackRemoveFromCart,
  trackViewBrandPage,
  trackViewRecipePage,
} from '../../util/analytics/custom-events';
import { placeholderUrl } from '../../util/functions/ui.functions';
import {
  selectBasketfulJsIsReady,
  selectBrandDetails,
  selectBrandFetched,
  selectBundleLineItemsFlat,
  selectBundleRecipes,
  selectSelectedRecipeId,
  selectSiteBasket,
  selectSiteBasketCount,
} from '../../redux/selectors/recipeSelectors';
import { BundleLineItem, BundleRecipe } from '../../util/bonsaiClient';
import BrandProductCard from './components/BPProductCard';
import useStyles from './BrandPageLayoutStyles';
import BrandRecipeCard from './components/BPRecipeCard';
import BPHeader from './components/BPHeader';
import { AppDispatch } from '../../redux/store';
import { selectUserId } from '../../redux/selectors/profileSelectors';
import { Box } from '@mui/material';

interface BrandPageLayoutProps {
  utmData: { [key: string]: string | string[] | undefined };
}

const BrandPageLayout: FC<BrandPageLayoutProps> = ({ utmData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const bundleRecipes = useSelector(selectBundleRecipes);
  const selectedRecipeId = useSelector(selectSelectedRecipeId);
  const siteBasket = useSelector(selectSiteBasket);
  const siteBasketCount = useSelector(selectSiteBasketCount);
  const basketfulJsIsReady = useSelector(selectBasketfulJsIsReady);
  const bundleLineItemsFlat = useSelector(selectBundleLineItemsFlat);
  const brandDetails = useSelector(selectBrandDetails);
  const brandFetched = useSelector(selectBrandFetched);
  const userId = useSelector(selectUserId);
  const entryId = utmData.brand as string;
  const isDesktop = useMediaQuery({
    query: '(min-width: 900px)',
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('false');

  const {
    brand,
    companyName,
    campaignId,
    website,
    brandVideo,
    description,
    description2,
    description3,
    section1Header,
    section2Header,
    section3Header,
    section4Header,
    heroImage,
    heroText,
    heroText2,
    heroText3,
    heroTextSpacing,
    heroTextFontsize,
    heroTextFontsizeMobile,
    heroTextColor,
    heroTextShadowColor,
    heroTextLineHeight,
    heroTextFont,
    heroTextFontStyle,
    heroTextFont2,
    heroTextFontStyle2,
    heroTextFont3,
    heroTextFontStyle3,
    heroSubText,
    heroSubFont,
    heroSubFontsize,
    heroAlign,
    heroSubFontStyle,
    logo,
    primaryDark,
    primaryLight,
    secondaryDark,
    secondaryLight,
    basketfulPrimaryColor,
    basketfulSecondaryColor,
    sectionImage,
    heroImageAltText,
    pageTitle,
    logoImageAlt,
    sectionImageAltText,
  } = brandDetails || {};

  const customTheme = {
    palette: {
      primary: {
        main: primaryDark || SMART_COMMERCE_ORANGE,
        dark: primaryDark || SMART_COMMERCE_ORANGE,
        light: primaryLight || SMART_COMMERCE_ORANGE,
      },
      secondary: {
        dark: secondaryDark || SMART_COMMERCE_PURPLE,
        light: secondaryLight || undefined,
      },
    },
  };
  const classes = useStyles(customTheme)();
  const logoUrl = logo?.fields.file.url
    ? `https:${logo?.fields.file.url}`
    : undefined;
  const heroImageUrl = heroImage?.fields.file.url
    ? `https:${heroImage?.fields.file.url}`
    : undefined;
  const sectionImageUrl = sectionImage?.fields.file.url
    ? `https:${sectionImage?.fields.file.url}`
    : sectionImage?.fields.file.url;

  const handleFetchBasket = async () => {
    dispatch(setBasketfulJsIsReadyAction(true));
    dispatch(fetchSiteBasketAction());
  };

  // listen for basketfuljs ready event
  useEffect(() => {
    const existingScript = document?.getElementById('basketfuljs');
    const script = document.createElement('script');
    if (!existingScript) {
      script.src = `https://${process.env.NEXT_PUBLIC_SHOP_DOMAIN}/basketful.js`;
      script.async = true;
      document.body.addEventListener('BasketfulIsReady', handleFetchBasket);
      document.body.appendChild(script);
    }
    return () => {
      // clean up
      document.body.removeChild(script);
      document.body.removeEventListener('BasketfulIsReady', handleFetchBasket);
    };
  }, []);

  // listen for recipe add / remove event
  useEffect(() => {
    if (basketfulJsIsReady) {
      window.basketful.siteBasket.onBundleRemoved(
        (bundleItem: BasketBundleRemoved) => {
          dispatch(siteBundleRemovedAction(bundleItem));
        },
      );
    }
  }, [basketfulJsIsReady]);

  // listen for item add / remove event
  useEffect(() => {
    if (basketfulJsIsReady) {
      window.basketful.siteBasket.onItemRemoved(
        (bundleItem: BasketItemRemoved) => {
          dispatch(siteBundleItemRemovedAction(bundleItem));
        },
      );
    }
  }, [basketfulJsIsReady]);

  const onPostMessageReceived = useCallback(
    (event: any) => {
      if (
        basketfulJsIsReady &&
        event?.data?.type === 'siteBasket.getBasket' &&
        event?.data?.args?.forceReload === true
      ) {
        dispatch(resetSiteBasketAction());
      }
    },
    [basketfulJsIsReady],
  );

  // listen reload
  // this happens after some retailer add to carts
  useEffect(() => {
    if (basketfulJsIsReady) {
      window.addEventListener('message', onPostMessageReceived);
      return () => window.removeEventListener('message', onPostMessageReceived);
    }
  }, [onPostMessageReceived, basketfulJsIsReady]);

  useEffect(() => {
    // figure out what to do if a brand does not exist, for now we show the chef loading screen
    if (!brandFetched) {
      const hostname = window.location.hostname;
      dispatch(getBrandDetailsAndBasketfulContentAction(hostname));
    }
  }, [brandFetched]);

  useEffect(() => {
    if (campaignId) {
      trackViewBrandPage({
        companyName,
        brandName: brand,
        brandId: entryId,
        brandType: 'brand',
        currentUserId: userId,
        store: undefined,
        campaignId: campaignId,
        eventSourcePage: EventSourcePage.BrandPage,
        eventSourceComponent: EventSourceComponent.RecipeCard,
      });
    }
  }, [brandDetails]);

  const handleOpenPrivacy = () => {
    window.location.href = SMART_COMMERCE_PRIVACY_POLICY_URL;
  };

  const handleOpenCart = () => {
    openSiteBasket({
      primaryColor: basketfulPrimaryColor?.replace('#', ''),
      secondaryColor: basketfulSecondaryColor?.replace('#', ''),
    });
  };

  const handleRecipeCardClick = (recipe: BundleRecipe) => {
    dispatch(setSelectedRecipeIdAction(recipe.id));
    trackViewRecipePage({
      companyName,
      brandName: brand,
      brandId: entryId,
      currentUserId: userId,
      store: undefined,
      recipe,
      campaignId: campaignId,
      eventSourcePage: EventSourcePage.RecipePage,
      eventSourceComponent: EventSourceComponent.RecipeDialog,
    });
  };

  const recipeIsInBasket = (id: string) =>
    siteBasket?.listBundles.filter(b => b?.id === id)?.length > 0;

  const productIsInBasket = (upc: string) =>
    siteBasket?.listItems.filter(item => item?.upc === upc)?.length > 0;

  // TODO:// do something else when loaded?
  if (!website) return <ChefLoader size="200px" />;

  const eventData = {
    source: EventSourcePage.BrandPage,
    currentStore: undefined, // this will never be set
    eventSourcePage: EventSourcePage.BrandPage,
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleCloseDialog = () => {
    dispatch(setSelectedRecipeIdAction(undefined));
  };

  const removeRecipe = async (
    recipe: BundleRecipe,
    sourceComponent: EventSourceComponent,
  ) => {
    await dispatch(removeSiteBundleAction({ id: recipe.id }));
    Mixpanel.track('Item removed', {
      actionType: 'cart',
      itemType: 'recipe',
      recipe,
      source: EventSourcePage.BrandPage,
    });

    trackRemoveFromCart({
      companyName: companyName,
      brandName: brand as string,
      campaignId: campaignId as string,
      currentUserId: userId,
      store: eventData.currentStore,
      recipe: recipe as BundleRecipe,
      eventSourcePage: eventData.eventSourcePage,
      eventSourceComponent: sourceComponent,
    });

    setShowSnackbar(true);
    setSnackbarMessage('Recipe removed from cart');
    handleCloseDialog();
  };

  const addRecipe = async (
    recipe: BundleRecipe,
    sourceComponent: EventSourceComponent,
  ) => {
    await dispatch(
      addSiteBundleAction({
        id: recipe.id,
        canonicalUrl: window.location.href,
      }),
    );

    Mixpanel.track('Item added', {
      actionType: 'cart',
      itemType: 'recipe',
      recipe,
      source: EventSourcePage.BrandPage,
      currentStore: undefined,
    });

    trackAddToCart({
      companyName: companyName,
      brandName: brand as string,
      campaignId: campaignId as string,
      currentUserId: userId,
      store: eventData.currentStore,
      recipe: recipe as BundleRecipe,
      eventSourcePage: eventData.eventSourcePage as EventSourcePage,
      eventSourceComponent: sourceComponent,
    });
    setShowSnackbar(true);
    setSnackbarMessage('Recipe added to cart');
    handleCloseDialog();
  };

  const removeProduct = async (lineItem: BundleLineItem) => {
    await dispatch(
      removeSiteBundleLineItemAction({
        upc: lineItem.upc,
      }),
    );
    Mixpanel.track('Item removed', {
      actionType: 'cart',
      itemType: 'product',
      lineItem,
      ...eventData,
    });

    trackRemoveFromCart({
      companyName: companyName,
      brandName: brand,
      campaignId: campaignId,
      currentUserId: userId,
      lineItem,
      eventSourcePage: eventData.eventSourcePage,
      eventSourceComponent: EventSourceComponent.RecipeCard,
    });
    setShowSnackbar(true);
    setSnackbarMessage('Product removed from cart');
  };

  const addProduct = async (lineItem: BundleLineItem) => {
    await dispatch(
      addSiteBundleLineItemAction({
        upc: lineItem.upc,
        quantity: 1,
      }),
    );
    Mixpanel.track('Item added', {
      actionType: 'cart',
      itemType: 'product',
      lineItem,
      ...eventData,
    });
    trackAddToCart({
      companyName: companyName,
      brandName: brand as string,
      campaignId: campaignId as string,
      currentUserId: userId,
      lineItem,
      eventSourcePage: eventData.eventSourcePage,
      eventSourceComponent: EventSourceComponent.RecipeCard,
    });
    setShowSnackbar(true);
    setSnackbarMessage('Product added to cart');
  };

  const trueHeroTextFont = heroTextFont || undefined;
  const trueHeroSubFontsize = heroSubFontsize ? `${heroSubFontsize}px` : '22px';
  const trueHeroTextFontsize = heroTextFontsize
    ? `${heroTextFontsize}px`
    : '72px';
  const trueHeroTextFontsizeMobile = heroTextFontsizeMobile
    ? `${heroTextFontsizeMobile}px`
    : '32px';

  return (
    <Grid container className={classes.wholeContainer}>
      <PageHead title={pageTitle ?? 'Brand Page'} />
      {logoUrl && (
        <BPHeader logoUrl={logoUrl} url={website} logoImageAlt={logoImageAlt} />
      )}

      {heroImageUrl && (
        <Grid item xs={12} className={classes.heroContainer}>
          <Image
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
            src={heroImageUrl}
            alt={heroImageAltText ?? 'Header image:'}
            layout="fill"
            className={classes.heroImage}
          />
          {heroText && (
            <>
              <div
                className={classes.heroText}
                style={{
                  textAlign: heroAlign || 'center',
                  padding: isDesktop ? '60px' : '24px',
                  color: heroTextColor || customTheme.palette.primary.light,
                  textShadow: `3px 3px ${heroTextShadowColor}` || 'none',
                  letterSpacing: heroTextSpacing
                    ? `${heroTextSpacing}px`
                    : 'none',
                  fontSize: isDesktop
                    ? trueHeroTextFontsize
                    : trueHeroTextFontsizeMobile,
                  lineHeight:
                    isDesktop && heroText2 && heroTextLineHeight
                      ? `${heroTextLineHeight}px`
                      : undefined,
                  fontFamily: trueHeroTextFont,
                  fontStyle: heroTextFontStyle || 'none',
                }}
              >
                <div>{heroText}</div>
                {heroText2 && (
                  <div
                    style={{
                      fontFamily: heroTextFont2 || trueHeroTextFont,
                      fontStyle: heroTextFontStyle2 || 'none',
                    }}
                  >
                    {heroText2}
                  </div>
                )}

                {heroText3 && (
                  <div
                    style={{
                      fontFamily: heroTextFont3 || trueHeroTextFont,
                      fontStyle: heroTextFontStyle3 || 'none',
                    }}
                  >
                    {heroText3}
                  </div>
                )}
              </div>
              {heroSubText && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: isDesktop ? 60 : 16,
                    left: isDesktop ? 60 : 16,
                    color: heroTextColor || customTheme.palette.primary.light,
                    textShadow: `1px 1px ${heroTextShadowColor}` || 'none',
                    fontSize: isDesktop ? trueHeroSubFontsize : '18px',
                    fontFamily: heroSubFont || trueHeroTextFont,
                    fontStyle: heroSubFontStyle || 'none',
                  }}
                >
                  {heroSubText}
                </div>
              )}
            </>
          )}
        </Grid>
      )}
      <Grid
        className={classes.gridContainer}
        style={{ marginTop: '8px' }}
        container
        direction="row"
        spacing={2}
      >
        {bundleRecipes && (
          <>
            <Grid item xs={12}>
              {section1Header && (
                <Typography className={classes.sectionHeader} variant="h1">
                  {section1Header}
                </Typography>
              )}
            </Grid>

            {bundleRecipes.map((recipe: BundleRecipe) => (
              <Grid className={classes.recipeCard} key={recipe.id} item xs={6}>
                <BrandRecipeCard
                  recipe={recipe}
                  customTheme={customTheme}
                  isInBasket={recipeIsInBasket(recipe.id)}
                  viewRecipe={handleRecipeCardClick}
                  addRecipe={() =>
                    addRecipe(recipe, EventSourceComponent.RecipeCard)
                  }
                  removeRecipe={() =>
                    removeRecipe(recipe, EventSourceComponent.RecipeCard)
                  }
                />
              </Grid>
            ))}
          </>
        )}
        {sectionImageUrl && (
          <>
            <Grid item xs={12}>
              {section2Header && (
                <Typography className={classes.sectionHeader} variant="h2">
                  {section2Header}
                </Typography>
              )}
            </Grid>

            <Grid className={classes.brandImageGrid} item xs={12}>
              <div className={classes.brandImageContainer}>
                <Image
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
                  src={sectionImageUrl}
                  alt={sectionImageAltText ?? 'Section image'}
                  className={classes.brandImage}
                  layout="fill"
                />
              </div>
            </Grid>
            <Grid className={classes.brandText} item xs={12}>
              {description && (
                <Typography style={{ marginBottom: '8px' }} variant="body1">
                  {description}
                </Typography>
              )}

              {description2 && (
                <Typography style={{ marginBottom: '8px' }} variant="body1">
                  {description2}
                </Typography>
              )}

              {description3 && (
                <Typography
                  style={{ marginBottom: '8px' }}
                  variant="body1"
                ></Typography>
              )}
            </Grid>
          </>
        )}
        {bundleLineItemsFlat && bundleLineItemsFlat?.length > 0 && (
          <>
            <Grid item xs={12}>
              {section3Header && (
                <Typography className={classes.sectionHeader} variant="h2">
                  {section3Header}
                </Typography>
              )}
            </Grid>

            {bundleLineItemsFlat.map((lineItem: BundleLineItem) => (
              <Grid
                className={classes.recipeCard}
                key={lineItem.id}
                item
                xs={6}
              >
                <BrandProductCard
                  customTheme={customTheme}
                  lineItem={lineItem}
                  isInBasket={productIsInBasket(lineItem.upc)}
                  addProduct={() => addProduct(lineItem)}
                  removeProduct={() => removeProduct(lineItem)}
                />
              </Grid>
            ))}
          </>
        )}

        {brandVideo && (
          <>
            <Grid item xs={12}>
              {section4Header && (
                <Typography className={classes.sectionHeader} variant="h2">
                  {section4Header}
                </Typography>
              )}
            </Grid>
            <Grid style={{ marginBottom: '16px', width: '100%' }} item xs={12}>
              <div className={classes.iFrameContainer}>
                <Iframe
                  url={brandVideo}
                  className={classes.iFrame}
                  position="absolute"
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '98px',
          backgroundColor: customTheme.palette.secondary.dark,
        }}
      >
        <Box
          style={{ color: customTheme.palette.secondary.light }}
          className={classes.footerButtons}
        >
          <ButtonBase
            className={classes.footerButton}
            onClick={handleOpenPrivacy}
          >
            Privacy Policy
          </ButtonBase>
        </Box>
      </Grid>
      <div className={classes.cartButtonContainer}>
        <Button
          variant="contained"
          className={classes.cartButton}
          onClick={handleOpenCart}
          aria-label="Click to open cart"
        >
          <div>
            <ShoppingCartIcon className={classes.cartIconCC} />
            {siteBasketCount === 0 && (
              <div aria-label="No items in cart." className={classes.cartCount}>
                No items
              </div>
            )}
            {siteBasketCount !== 0 && (
              <div
                className={classes.cartCount}
                aria-label={`${siteBasketCount} ${
                  siteBasketCount === 1 ? `item in cart` : `items in cart`
                }`}
              >
                {siteBasketCount} {siteBasketCount === 1 ? 'item' : 'items'}
              </div>
            )}
          </div>
        </Button>
      </div>

      <RecipeDialog
        customTheme={customTheme}
        recipeId={selectedRecipeId}
        isOpen={!!selectedRecipeId}
        handleClose={handleCloseDialog}
        campaignId={campaignId}
        isInBasket={recipeIsInBasket(selectedRecipeId)}
        addRecipe={recipe =>
          addRecipe(recipe, EventSourceComponent.RecipeDialog)
        }
        removeRecipe={recipe =>
          removeRecipe(recipe, EventSourceComponent.RecipeDialog)
        }
      />
      <Snackbar
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      />
    </Grid>
  );
};

export default BrandPageLayout;
