import React, { FC, forwardRef, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { selectSelectedRecipeId } from 'src/redux/selectors/recipeSelectors';
import { useSelector } from 'react-redux';
import { BundleRecipe } from 'src/util/bonsaiClient';
import RecipeViewContainer from '../../pages/recipe';
import Mixpanel from '../../util/mixpanel';
import useStyles from './RecipeDialogStyles';

// eslint-disable-next-line react/display-name
const Transition: any = forwardRef((props: any, ref: any) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface DialogProps {
  isOpen: boolean;
  recipeId?: string;
  handleClose: () => void;
  logoUrl?: string;
  customTheme?: any;
  campaignId?: string;
  isInBasket: boolean;
  addRecipe: (recipe: BundleRecipe) => void;
  removeRecipe: (recipe: BundleRecipe) => void;
}

const RecipeDialog: FC<DialogProps> = ({
  isOpen,
  recipeId,
  handleClose,
  customTheme,
  isInBasket,
  addRecipe,
  removeRecipe,
}) => {
  const classes = useStyles();
  const selectedRecipeId = useSelector(selectSelectedRecipeId);
  const closeDialog = () => {
    handleClose();
    Mixpanel.track('Recipe closed', {
      recipeId,
    });
  };
  if (!isOpen) return null;
  return (
    <Dialog
      fullScreen
      disableEnforceFocus
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.recipeCard}
    >
      <IconButton
        className={classes.closeButton}
        id="close-recipe-button"
        onClick={closeDialog}
        size="large"
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      {selectedRecipeId && (
        <RecipeViewContainer
          customTheme={customTheme}
          isInBasket={isInBasket}
          removeRecipe={removeRecipe}
          addRecipe={addRecipe}
        />
      )}
    </Dialog>
  );
};

export default memo(RecipeDialog);
