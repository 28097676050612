import React, { FC, memo } from 'react';
import Image from 'next/legacy/image';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { MISSING_IMAGE_URL } from '../../../util/constants';
import { placeholderUrl } from '../../../util/functions/ui.functions';
import { selectRecipeIsBusy } from '../../../redux/selectors/recipeSelectors';
import { BundleLineItem } from '../../../util/bonsaiClient';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '242px',
    textAlign: 'start',
    boxShadow: 'none',
    marginBottom: '16px',
    border: '1px solid #e6e6e6',
    transition: 'all ease 200ms',
    '@media (min-width: 900px)': {
      height: '252px',
    },
  },
  content: {
    padding: '8px',
    fontSize: '14px',
    minHeight: '54px',
    fontWeight: 500,
    '@media (min-width: 900px)': {
      fontSize: '16px',
    },
  },
  add: {
    position: 'absolute',
    width: 'calc(100% - 16px)',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 0,
    marginBottom: '8px',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      opacity: 0.8,
    },
    '@media (min-width: 900px)': {
      marginBottom: '12px',
      width: 'calc(100% - 16px)',
    },
  },
});

interface ProductCardProps {
  lineItem: BundleLineItem;
  customTheme: any;
  isInBasket: boolean;
  addProduct: () => void;
  removeProduct: () => void;
}

const ProductCard: FC<ProductCardProps> = ({
  lineItem,
  isInBasket,
  customTheme,
  addProduct,
  removeProduct,
}) => {
  const classes = useStyles();
  const recipeIsBusy = useSelector(selectRecipeIsBusy);

  const nameShortened =
    lineItem.name.length > 56
      ? `${lineItem?.name?.slice(0, 56)}...`
      : lineItem.name;

  return (
    <Card className={classes.root}>
      <div>
        <div
          style={{
            borderRadius: '12px',
            height: '8.25rem',
            position: 'relative',
            overflow: 'hidden',
            margin: '8px',
            marginBottom: '0',
          }}
        >
          <Image
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${placeholderUrl}`}
            src={lineItem.imageUrl || MISSING_IMAGE_URL}
            alt={lineItem.name}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className={classes.content}>{nameShortened}</div>
      </div>
      {isInBasket ? (
        <Button
          onClick={removeProduct}
          fullWidth
          className={classes.add}
          disabled={recipeIsBusy}
          size="small"
          aria-label={`Remove ${lineItem.name} to cart`}
          style={{
            backgroundColor: customTheme.palette.primary.main,
          }}
        >
          Remove from Cart
        </Button>
      ) : (
        <Button
          onClick={addProduct}
          fullWidth
          className={classes.add}
          disabled={recipeIsBusy}
          size="small"
          aria-label={`Add ${lineItem.name} to cart`}
          style={{
            backgroundColor: customTheme.palette.primary.main,
          }}
        >
          Add to Cart
        </Button>
      )}
    </Card>
  );
};

export default memo(ProductCard);
