import { memo } from 'react';

function HealthPage() {
  return (
    <div
      style={{
        color: '#000',
        background: '#fff',
        fontFamily:
          // eslint-disable-next-line max-len
          "-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Fira Sans', Avenir, 'Helvetica Neue', 'Lucida Grande', sans-serif",
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            borderRight: '1px solid rgba(0, 0, 0,.3)',
            margin: '0',
            marginRight: '20px',
            padding: '10px 23px 10px 0',
            fontSize: '24px',
            fontWeight: 500,
            verticalAlign: 'top',
          }}
        >
          200
        </div>
        <div
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: 'inherit',
            margin: '0',
            padding: '0',
          }}
        >
          gomyxx is a &quot;Go&quot; 🚀
        </div>
      </div>
    </div>
  );
}

export default memo(HealthPage);
